import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';

import DealerList from './components/dealerList.vue';
import OrgList1 from './components/org-list1.vue';
import FeeSuject from './components/fee-suject.vue';

formCreate.component('DealerList', DealerList);
formCreate.component('OrgList1', OrgList1);
formCreate.component('FeeSuject', FeeSuject);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'cost_warning_ratio_configuration_form',
      costRulesTierList: [],
      buttons: {
        submit: this.formConfig.type !== 'view',
      },
    };
  },

  methods: {
    // 设置规则
    setRule(item) {
      const v = item;
      if (v.field === 'dealerName') { // 经销商
        v.props = {
          ...v.props,
          params: {
            functionCode: 'dealerList',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 6 },
          formConfig: this.formConfig,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            const organizations = [];
            e.forEach((m) => {
              const obj = m;
              organizations.push({
                customerName: obj.customerName,
                customerCode: obj.customerCode,
              });
            });
            this.setValue({
              dealerName: organizations || [],
            });
          },
        };
      }
      if (v.field === 'organizations') { // 多选组织
        v.props = {
          ...v.props,
          params: {
            functionCode: 'fee_org_list_1',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 6 },
          formConfig: this.formConfig,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            const organizations = [];
            e.forEach((m) => {
              const obj = m;

              organizations.push({
                organizationName: obj.orgName,
                orgName: obj.orgName,
                orgCode: obj.orgCode,
                organizationCode: obj.orgCode,
              });
            });
            this.setValue({

              organizations: organizations || [],
            });
          },
        };
      }
      if (v.field === 'itemList') {
        v.props = {
          ...v.props,
          saveRef: this.saveSujectRef,
          formConfig: this.formConfig,
        };
      }
      if (v.field === 'dimension') {
        v.on = {
          ...v.on,
          change: (e) => {
            if (e === 'org') {
              this.hiddenFields(true, ['dealerName']);
              this.hiddenFields(false, ['organizations']);
            } else {
              this.hiddenFields(true, ['organizations']);
              this.hiddenFields(false, ['dealerName']);
            }
          },
        };
      }

      return item;
    },

    // 完成渲染
    formComplete() {
      const { type } = this.formConfig;
      this.hiddenFields(true, ['dealerName', 'organizations']);
      const budgetName = this.getRule('budgetName');
      budgetName.restful = '/tpm/tpmBudgetSubjectsController/list';

      budgetName.props = {
        ...budgetName.props,
        filterable: true,
        remote: true,
        remoteParams: 'budgetSubjectsName',
      };
      budgetName.optionsKey = {
        label: 'budgetSubjectsName',
        value: 'budgetSubjectsCode',
      };
      budgetName.on = {
        ...budgetName.on,
        getItem: (val) => {
          this.setValue({ budgetName: val.budgetSubjectsName, budgetCode: val.budgetSubjectsCode });
        },
      };
      if (type === 'view') {
        request.post('/tpm/tpmfeewarn/query', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            if (res.result.dimension === 'org') {
              this.hiddenFields(false, ['organizations']);
              this.hiddenFields(true, ['dealerName']);
            } else {
              this.hiddenFields(false, ['dealerName']);
              this.hiddenFields(true, ['organizations']);
            }
            this.setValue({
              ...res.result,
              itemList: res.result.ratioRespVos,
              dealerName: res.result.weiduRespVos.map(((item) => ({
                customerName: item.name,
                customerCode: item.code,
              })
              )),
              organizations: res.result.weiduRespVos.map(((item) => ({
                organizationName: item.name,
                organizationCode: item.code,
              })
              )),
            });
          }
        });
      }
    },

    saveSujectRef(ref) {
      this.sujectRef = ref;
    },
    // 提交
    submit() {
      const isRuleRight = this.fApi.method('itemList', 'submitInfoObj')();// 子组件验证
      const formData = this.getFormData(); // 获取表单的值
      if (isRuleRight && formData) {
        const arr = formData.organizations ? formData.organizations : formData.dealerName;
        formData.weiduReqVos = arr.map((item) => ({
          code: formData.dimension === 'org' ? item.orgCode : item.customerCode,
          name: formData.dimension === 'org' ? item.orgName : item.customerName,
        }));
        formData.ratioReqVos = formData.itemList.map((item) => ({
          label: item.label,
          ratio: item.ratio,
        }));
        delete formData.organizations;
        delete formData.dealerName;
        delete formData.itemList;

        // 默认新增
        let url = '/tpm/tpmfeewarn/save';
        // // 有id  就是编辑
        if (this.formConfig.row && this.formConfig.row.id) {
          url = '/tpm/tpmCostRules/update';
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};

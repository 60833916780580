import BasePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from '../form';

export default {
  name: 'cost_warning_ratio_configuration',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('cost_warning_ratio_configuration');
  },
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (['add', 'view'].includes(val.code)) {
        this.formConfig = {
          code: val.code,
          type: val.code,
          row,
        };
        if (val.code === 'add') {
          this.modalConfig.title = '新增';
        } else if (val.code === 'view') {
          this.modalConfig.title = '查看';
        }
        this.openFull();
      } else if (val.code === 'enable') {
        if (this.selection.length > 0) {
          this.$confirm('确认要启用吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              request.post('/tpm/tpmfeewarn/enable', { ids: row.map((v) => v.id) }).then((res) => {
                if (res.success) {
                  this.$message({
                    type: 'success',
                    message: '操作成功',
                  });
                  this.getList();
                }
              });
            });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      } else if (val.code === 'disable') {
        if (this.selection.length > 0) {
          this.$confirm('确认要禁用吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              request.post('/tpm/tpmfeewarn/disable', { ids: row.map((v) => v.id) }).then((res) => {
                if (res.success) {
                  this.$message({
                    type: 'success',
                    message: '操作成功',
                  });
                  this.getList();
                }
              });
            });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      }
    },
  },
};

<template>
  <div class="addUser-position-information">
    <el-form
      :model="infoObj"
      label-position="right"
      ref="infoObjFormRef"
      label-width="125px"
    >
      <el-row v-for="(item, index) in infoObj.itemList" :key="index">
        <el-col :span="8">
          <el-form-item
            :prop="'itemList.' + index + '.ratio'"
            maxlength="8"
            :rules="{
              required: true,
              message: '请输入预警比例',
              trigger: 'blur',
            }"
            label="预警比例"
          >
            <el-input-number
              v-model="item.ratio"
              :min="0"
              :precision="2"
              :disabled="!isDisable || formConfig.type === 'view'"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="预警提示"
            :prop="'itemList.' + index + '.label'"
            :rules="{
              required: true,
              message: '预警提示不能为空',
              trigger: 'change',
            }"
          >
            <el-select
              @change="changeBudgetList(item, index)"
              :disabled="!isDisable || formConfig.type === 'view'"
              v-model="item.label"
            >
              <el-option
                v-for="(item, index) in budgetAccount"
                :key="index"
                :label="item.dictValue"
                :value="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <div v-if="!isDisable || formConfig.type !== 'view'">
          <el-button
            v-if="isDisable && index === 0"
            type="text"
            @click="addArrItem(infoObj.itemList)"
          >
            <i style="font-size: 20px" class="el-icon-circle-plus-outline"></i>
          </el-button>
          <el-button
            v-if="isDisable && index > 0"
            type="text"
            @click="delArrItem(infoObj.itemList, index)"
          >
            <i style="font-size: 20px" class="el-icon-delete"></i>
          </el-button>
        </div>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'FeeSuject',
  components: {},
  props: {
    isDisable: {
      type: Boolean,
      default: true,
    },
    saveRef: Function,
    value: Array,
    formConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      infoObj: {
        itemList: [
          {
            dictValue: '',
            dictCode: '',
            ratio: null,
            label: '',
          },
        ],
      },
      budgetAccount: [],
    };
  },
  async created() {
    console.log('基础信息', this.formConfig, this.isDisable);
    this.getBudgetSubject();
    if (this.saveRef) {
      this.saveRef(this);
    }
    // if (this.formConfig.pageData && this.formConfig.pageData) {
    //   this.getDetail();
    // }
  },
  watch: {
    'infoObj.itemList': {
      deep: true,
      immediate: true,
      handler(itemList) {
        this.$emit('input', itemList);
        this.$emit('change', itemList);
      },
    },
    isDisable(newVal, oldVal) {
      this.isDisable = newVal;
    },
    value: {
      handler(newVal, oldVal) {
        console.log('🚀 ~ value ~ newVal:', newVal);
        if (newVal.length > 0) {
          this.infoObj.itemList = newVal;
        }
      },
      deep: true,
    },
  },
  methods: {
    // 获取预警提示
    getBudgetSubject(code) {
      request
        .post('/mdm/mdmdictdata/list', { dictTypeCode: 'tpm_fee_warn_level' })
        .then((res) => {
          if (res.success) {
            this.budgetAccount = res.result;
          }
        });
    },

    // 清空预算科目
    clearBudgetSubject() {
      this.infoObj.itemList.forEach((item) => {
        item.budgetSubjectCode = '';
        item.budgetSubjectName = '';
      });
    },
    // 改变预算科目
    changeBudgetList(val, index) {
      console.log('🚀 ~ changeBudgetList ~ val:', val);
      const item = val;
      if (item.label) {
        this.budgetAccount.forEach((t) => {
          if (item.label === t.dictCode) {
            item.dictValue = t.dictValue;
            item.dictCode = t.dictCode;
          }
        });
        this.infoObj.itemList[index] = item;
      } else {
        this.infoObj.itemList[index].dictValue = '';
        this.infoObj.itemList[index].dictCode = '';
      }
    },

    //   获取 详情
    getDetail(data) {
      this.infoObj.itemList = data;
    },

    submitInfoObj() {
      let isRight = false;
      this.$refs.infoObjFormRef.validate((valid) => {
        if (valid) {
          isRight = valid;
        }
      });
      return isRight;
    },

    //  新增每一i想
    addArrItem(arr) {
      arr.push({
        budgetSubjectCode: '',
        amount: '',
        remarks: '',
      });
    },

    // 删除每一项
    delArrItem(arr, index) {
      this.$confirm('确认删除吗？', '提示').then(() => {
        arr.splice(index, 1);
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/.el-button--small.is-circle {
  padding: 5px !important;
}
/deep/.el-form-item__label {
  font-size: 12px !important;
}
.addUser-position-information {
  width: 100%;
  .footer-btn {
    position: absolute;
    bottom: 0;
    right: 10px;

    button {
      width: 100px;
    }
  }

  .arrItemTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .arrItemTitle-left {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0 20px 0 0;
        font-size: 16px;
        height: 20px;
        line-height: 20px;
        font-weight: 600;
      }
    }
    .arrItemTitle-right {
    }
  }
}
</style>

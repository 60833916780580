<template>
  <div class="custom-select">
    <div
      :class="{ 'disabled-select': formConfig.type === 'view' }"
      class="cost-select el-textarea el-input--small"
      style="min-height: 32px"
      @click="openModal"
    >
      <el-tag
        v-for="(item, index) in value"
        :key="index"
        closable
        @close="handleClose(item)"
        >{{ item.organizationName }}</el-tag
      >
    </div>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'OrgList',
  props: {
    autosize: Object,
    title: String,
    value: Array,
    disabled: Boolean,
    getValue: Function,
    params: {
      type: Object,
      default: () => ({}),
    },
    formConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  components: {
    SelectConfig,
  },
  created() {},
  methods: {
    // 新增弹窗
    openModal() {
      let params = {
        ...this.params,
        paramData: { orgType: 'area', enableStatus: '009' },
      };
      if (this.disabled || Object.keys(this.params).length === 0) {
        return;
      }
      params = {
        ...params,
        idKey: 'orgCode',
        data: this.value || [],
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择回调
    onGetSelect(val) {
      this.$emit('change', val);
    },
    // 清除选择
    clearFn(e) {
      e.stopPropagation();
      this.$emit('change');
    },
    handleClose(item) {
      if (this.disabled) {
        return;
      }
      const data = this.value.filter(
        (v) => v.organizationCode !== item.organizationCode,
      );
      this.$emit('input', data);
    },
  },
};
</script>

<style lang="less" scoped>
.cost-select {
  position: relative;
  border: 1px solid #dddfe6;
  border-radius: 5px;
  padding: 5px;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
.disabled-select {
  pointer-events: none;
  background: #f5f7fa;
}
</style>
